@use '@/styles/utils/mixins.scss' as *;

.blogListWrapper {
  .headingElement {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: 'KiaSignature';
      font-weight: 600;
      font-style: normal;
      &.titleXl {
        font-size: 60px;
        line-height: 60px;
        @include tab() {
          font-size: 48px;
          line-height: 60px;
        }
        @include mob() {
          font-size: 36px;
          line-height: 43.2px;
        }
      }
      &.titleLg {
        font-size: 48px;
        line-height: 60px;
        @include tab() {
          font-size: 36px;
          line-height: 43.2px;
        }
      }
      &.titleMd {
        font-size: 24px;
        line-height: 36px;
        @include tab() {
          font-size: 20px;
          line-height: 24px;
        }
      }
      &.titleSm {
        font-size: 20px;
        line-height: 24px;
      }
      &.titleXs {
        font-size: 16px;
        line-height: 19.2px;
      }
    }
  }
  .blogListHeader {
    .blogListHeaderCTA {
      @media screen and (max-width: 576px) {
        display: none;
      }
    }
  }
  .blogListHeaderCTAmobile {
    .btnCTA {
      display: block;
    }
    @media screen and (min-width: 576px) {
      display: none;
    }
  }
  .blogListPagination {
    background-color: $keyline;
    border-radius: 15px;
    color: $kiaMidnightBlack;
    padding: 4px 10px;
    width: auto;
    margin-right: 2px;
    line-height: 1;
    min-width: 48px;
    text-align: center;
    span {
      font-size: 12px;
      line-height: 14.4px;
    }
  }
  .blogListNavigation {
    display: flex;
    align-items: center;
    .blogListPrev,
    .blogListNext {
      width: 24px;
      height: 24px;
      margin-left: 6px;
      svg {
        height: 100%;
        width: 100%;
      }
      @media screen and (max-width: 767px) {
        width: 48px;
        height: 48px;
      }
    }
  }
}
.newsFeedCard {
  .headingElement {
    position: relative;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: 'KiaSignature';
      font-weight: 600;
      font-style: normal;
      &.titleXl {
        font-size: 60px;
        line-height: 60px;
        @include tab() {
          font-size: 48px;
          line-height: 60px;
        }
        @include mob() {
          font-size: 36px;
          line-height: 43.2px;
        }
      }
      &.titleLg {
        font-size: 48px;
        line-height: 60px;
        @include tab() {
          font-size: 36px;
          line-height: 43.2px;
        }
        @include mob() {
          font-size: 24px;
          line-height: 28.8px;
        }
      }
      &.titleMd {
        font-size: 24px;
        line-height: 36px;
        @include tab() {
          font-size: 20px;
          line-height: 24px;
        }
      }
      &.titleSm {
        font-size: 20px;
        line-height: 24px;
      }
      &.titleXs {
        font-size: 16px;
        line-height: 19.2px;
      }
    }
  }
  .newsFeedImgBlock {
    position: relative;
    .newsFeedImage {
      width: 100%;
      height: auto;
    }
  }
}

.latestNewsWrapper {
  background-color: $kiaMidnightBlack;
  .headingElement {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: 'KiaSignature';
      font-weight: 600;
      font-style: normal;
      &.titleXl {
        font-size: 60px;
        line-height: 60px;
        @include tab() {
          font-size: 48px;
          line-height: 60px;
        }
        @include mob() {
          font-size: 36px;
          line-height: 43.2px;
        }
      }
      &.titleLg {
        font-size: 48px;
        line-height: 60px;
        @include tab() {
          font-size: 36px;
          line-height: 43.2px;
        }
      }
      &.titleMd {
        font-size: 24px;
        line-height: 36px;
        @include tab() {
          font-size: 20px;
          line-height: 24px;
        }
      }
      &.titleSm {
        font-size: 20px;
        line-height: 24px;
      }
      &.titleXs {
        font-size: 16px;
        line-height: 19.2px;
      }
    }
    .titleText {
      color: $kiaPolarWhite;
    }
  }
  .linkWhite {
    color: $kiaPolarWhite;
  }

}
.socialMediaImg {
  width: 100%;
  object-fit: cover;
  aspect-ratio: 1 / 1;
}
