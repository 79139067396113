@use '@/styles/utils/mixins.scss' as *;

.articlePageWrapper {
  .mainNav {
    .backBtn {
      display: flex;
      background: transparent !important;
      width: max-content;
      flex: 0 0 auto;
      padding: 0;
      margin: 0 0 10px;
      height: auto !important;
      font-size: 16px;
      font-weight: 600;
      align-items: center;
      border-radius: 0 !important;
      color: $kiaMidnightBlack;
      -webkit-transition: all 450ms ease-in-out;
      -moz-transition: all 450ms ease-in-out;
      -o-transition: all 450ms ease-in-out;
      -ms-transition: all 450ms ease-in-out;
      transition: all 450ms ease-in-out;
      svg {
        padding-bottom: 1px;

        &:focus {
          color: $kiaMidnightBlack;
          outline: 0;
        }
      }
      .backBtnText {
        -webkit-transition: all 450ms ease-in-out;
        -moz-transition: all 450ms ease-in-out;
        -o-transition: all 450ms ease-in-out;
        -ms-transition: all 450ms ease-in-out;
        transition: all 450ms ease-in-out;
        padding-bottom: 1px;
        position: relative;
        &::before {
          content: '';
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0px;
          height: 1px;
          width: 0;
          background-color: $kiaMidnightBlack;
          transition: width 0.3s;
        }

        &:focus {
          color: $kiaMidnightBlack;
          outline: 0;
        }
      }

      &:hover,
      &:active,
      &:focus {
        color: $kiaMidnightBlack;
        .backBtnText {
          color: $kiaMidnightBlack;
          &::before {
            width: 100%;
            transition: width 0.3s;
          }
        }
      }
      &.active {
        color: $kiaMidnightBlack;
        .backBtnText {
          color: $kiaMidnightBlack;
          &::before {
            width: 100%;
            transition: width 0.3s;
          }
        }
      }
    }
  }
  .headingElement {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: 'KiaSignature';
      font-weight: 600;
      font-style: normal;
      &.titleXl {
        font-size: 60px;
        line-height: 60px;
        @include tab() {
          font-size: 48px;
          line-height: 60px;
        }
        @include mob() {
          font-size: 36px;
          line-height: 43.2px;
        }
      }
      &.titleLg {
        font-size: 48px;
        line-height: 60px;
        @include tab() {
          font-size: 36px;
          line-height: 43.2px;
        }
      }
      &.titleMd {
        font-size: 24px;
        line-height: 36px;
        @include tab() {
          font-size: 20px;
          line-height: 24px;
        }
      }
      &.titleSm {
        font-size: 20px;
        line-height: 24px;
      }
      &.titleXs {
        font-size: 16px;
        line-height: 19.2px;
      }
    }
  }
  .heroImageWrapper {
    .heroImageBlock {
      .heroImage {
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .newsFeedCard {
    .headingElement {
      position: relative;
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-family: 'KiaSignature';
        font-weight: 600;
        font-style: normal;
        &.titleXl {
          font-size: 60px;
          line-height: 60px;
          @include tab() {
            font-size: 48px;
            line-height: 60px;
          }
          @include mob() {
            font-size: 36px;
            line-height: 43.2px;
          }
        }
        &.titleLg {
          font-size: 48px;
          line-height: 60px;
          @include tab() {
            font-size: 36px;
            line-height: 43.2px;
          }
          @include mob() {
            font-size: 24px;
            line-height: 28.8px;
          }
        }
        &.titleMd {
          font-size: 24px;
          line-height: 36px;
          @include tab() {
            font-size: 20px;
            line-height: 24px;
          }
        }
        &.titleSm {
          font-size: 20px;
          line-height: 24px;
        }
        &.titleXs {
          font-size: 16px;
          line-height: 19.2px;
        }
      }
    }
    .newsFeedImgBlock {
      position: relative;
      .newsFeedImage {
        width: 100%;
        height: auto;
      }
    }
  }
  .serviceBlockContent {
    .serviceImgBlock {
      .serviceImage {
        @media screen and (max-width: 991.98px) {
          margin-left: auto;
          margin-right: auto;
          width: 100%;
          height: auto;
          img {
            width: 100%;
          }
        }
      }
    }
  }
}

.latestNewsWrapper {
  background-color: $kiaMidnightBlack;
  .headingElement {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: 'KiaSignature';
      font-weight: 600;
      font-style: normal;
      &.titleXl {
        font-size: 60px;
        line-height: 60px;
        @include tab() {
          font-size: 48px;
          line-height: 60px;
        }
        @include mob() {
          font-size: 36px;
          line-height: 43.2px;
        }
      }
      &.titleLg {
        font-size: 48px;
        line-height: 60px;
        @include tab() {
          font-size: 36px;
          line-height: 43.2px;
        }
      }
      &.titleMd {
        font-size: 24px;
        line-height: 36px;
        @include tab() {
          font-size: 20px;
          line-height: 24px;
        }
      }
      &.titleSm {
        font-size: 20px;
        line-height: 24px;
      }
      &.titleXs {
        font-size: 16px;
        line-height: 19.2px;
      }
    }
    .titleText {
      color: $kiaPolarWhite;
    }
  }
  .linkWhite {
    color: $kiaPolarWhite;
  }
}
