@use '@/styles/utils/mixins.scss' as *;

.mainNav {
  position: absolute;
  top: 30px;
  width: 100%;
  z-index: 3;
  a:hover {
    text-decoration: none;
  }

  .mainNavItem {
    margin: 0 24px;
    padding: 13px 0;
    font-size: 16px;
    line-height: 1.2;
    font-weight: 600;
    color: $kiaMidnightBlack;
    position: relative;

    @include tab() {
      padding: 16px;
      border-bottom: 1px solid $kiaMidnightBlack;
      margin-right: 0;
      font-size: 18px;
    }

    &:hover {
      z-index: $headerNavItemZindex;
    }

    &:hover::before {
      content: '';
      width: 100%;
      height: 1px;
      background-color: $kiaMidnightBlack;
      position: absolute;
      left: 0;
      bottom: 12px;
    }
  }
}

.mainNavItemActive {
  z-index: $headerNavItemZindex;
}

.flexWrapper {
  .drawerContent {
    width: auto !important;
  }
}

.KiaOneNavigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow-x: auto;
  overflow-y: hidden;
  scroll-behavior: smooth;
  ul {
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: nowrap;
    list-style: none;
    li.menuItem {
      margin: 0 24px;
      display: block;
      text-wrap: nowrap;
      &:first-of-type {
        margin-left: 0;
      }
      &:last-of-type {
        margin-right: 0;
      }
      a {
        &.menuLink {
          padding: 10px 0;
          margin-bottom: 10px;
          font-size: 14px;
          line-height: 16.8px;
          font-weight: 600;
          color: $kiaCoolGrey;
          display: block;
          position: relative;
          &::before {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            height: 2px;
            width: 0;
            background-color: $kiaMidnightBlack;
            transition: width 0.3s;
          }
          &:hover::before,
          &:focus::before,
          &:active::before,
          &.menuLinkActive::before {
            width: 100%;
            transition: width 0.3s;
          }
          &:active,
          &:hover,
          &.menuLinkActive {
            color: $kiaMidnightBlack;
          }
        }
      }
    }
  }
}

.backBtn {
  display: flex;
  background: transparent !important;
  width: max-content;
  flex: 0 0 auto;
  padding: 0;
  margin: 0 0 10px;
  height: auto !important;
  font-size: 16px;
  font-weight: 600;
  align-items: center;
  border-radius: 0 !important;
  color: $kiaMidnightBlack;
  -webkit-transition: all 450ms ease-in-out;
  -moz-transition: all 450ms ease-in-out;
  -o-transition: all 450ms ease-in-out;
  -ms-transition: all 450ms ease-in-out;
  transition: all 450ms ease-in-out;
  svg {
    padding-bottom: 2px;

    &:focus {
      color: $kiaMidnightBlack;
      outline: 0;
    }
  }
  .backBtnText {
    -webkit-transition: all 450ms ease-in-out;
    -moz-transition: all 450ms ease-in-out;
    -o-transition: all 450ms ease-in-out;
    -ms-transition: all 450ms ease-in-out;
    transition: all 450ms ease-in-out;
    padding-bottom: 2px;
    position: relative;
    &::before {
      content: '';
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0px;
      height: 1px;
      width: 0;
      background-color: $kiaMidnightBlack;
      transition: width 0.3s;
    }

    &:focus {
      color: $kiaMidnightBlack;
      outline: 0;
    }
  }

  &:hover,
  &:active,
  &:focus {
    color: $kiaMidnightBlack;
    .backBtnText {
      color: $kiaMidnightBlack;
      &::before {
        width: 100%;
        transition: width 0.3s;
      }
    }
  }
  &.active {
    color: $kiaMidnightBlack;
    .backBtnText {
      color: $kiaMidnightBlack;
      &::before {
        width: 100%;
        transition: width 0.3s;
      }
    }
  }
}
